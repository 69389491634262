import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { APIUserFortniteAccountResponse, APIUserResponse } from '../resources/httpResponses';
import LoggedInUserComponent from './LoggedInUser';

const parsePremiumLevel = (level: number, expiresAt?: string) => {
  switch (level) {
    case 0:
      return 'Not Subscribed';
    case 1:
      return `Subscribed (Expires at ${new Date(expiresAt!).toLocaleDateString()})`;
    case 3:
      return 'Subscribed (Lifetime)';
  }
};

const parseBotStarts = (history: string[]) => {
  if (history.length === 0) return '0';
  return `${history.length} (Last on ${new Date(history[history.length - 1]).toLocaleDateString()})`;
}

const DashComponent = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [userData, setUserData] = useState<APIUserResponse | undefined>(undefined);
  const [userFortniteAccountData, setUserFortniteAccountData] = useState<APIUserFortniteAccountResponse | undefined>(undefined);
  useEffect(() => {
    document.title = 'ReconBot • Dashboard';

    (async () => {
      const code = searchParams.get('code');
      if (code) {
        searchParams.delete('code');
        setSearchParams(searchParams);

        const loginResponse = await window.http.login(code, `${window.location.protocol}//${window.location.host}/dash`);

        if (loginResponse.error) {
          setUserData({ error: loginResponse.error });
          return;
        }

        localStorage.setItem('login', JSON.stringify(loginResponse.response));

        let redirectTo = '/';
        if (localStorage.getItem('redirectAfterLogin')) {
          const redirectURL = new URL(localStorage.getItem('redirectAfterLogin') as string);
          redirectTo = redirectURL.href.replace(redirectURL.origin, '');

          localStorage.removeItem('redirectAfterLogin');
        }

        navigate(redirectTo);
        return;
      }

      if (!window.http.isLoggedIn) {
        localStorage.setItem('redirectAfterLogin', window.location.href);
        navigate('/login');
        return;
      }

      setUserData({ response: window.http.loginData! });
      const userFortniteAccountResponse = await window.http.fetchUserFortniteAccount();
      setUserFortniteAccountData(userFortniteAccountResponse);

      const userResponse = await window.http.fetchUser();
      setUserData(userResponse);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userData) return (
    <>
      <div className='dash-loading' />
      <LoggedInUserComponent />
    </>
  );

  const user = userData.response!;

  return (
    <div className='dash'>
      <div className='dash-user'>
        <img className='dash-user-img' alt='avatar' src={user.avatar.includes('a_') ? user.avatar.replace('.png', '.gif') : user.avatar} />
        <h1 className='dash-user-name'>{user.username}#{user.discriminator}</h1>
        <p className='dash-user-key'>ID</p>
        <p className='dash-user-value'>{user.id}</p>
        <p className='dash-user-key'>Fortnite Account</p>
        <p className='dash-user-value'>
          {
            userFortniteAccountData?.response?.displayName
            ? `${userFortniteAccountData.response.displayName} (${userFortniteAccountData.response.id})`
            : user.fortniteId || 'Not linked'
          }
        </p>
        <p className='dash-user-key'>Premium</p>
        <p className='dash-user-value'>{parsePremiumLevel(user.premiumType, user.premiumExpiresAt)}</p>
        <p className='dash-user-key'>Bots Started</p>
        <p className='dash-user-value'>{parseBotStarts(user.botHistory || [])}</p>
      </div>
      <LoggedInUserComponent />
    </div>
  );
}

export default DashComponent;
