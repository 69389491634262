import ReactDOM from 'react-dom';
import { useSpring, animated } from '@react-spring/web';

const BaseModal = ({ modal, onClose }: { modal: JSX.Element, onClose?: () => void }) => {
  const overlayAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 0.7 },
    config: {
      duration: 100,
    },
  });

  const modalAnimation = useSpring({
    opacity: 1,
    config: {
      duration: 100,
    },
  });

  return ReactDOM.createPortal(
    <>
      <animated.div className='modal-overlay' style={overlayAnimation} onClick={() => onClose?.()} />
      <animated.div className='modal-container' style={modalAnimation}>
        <p className='modal-closex' onClick={() => onClose?.()}>x</p>
        {modal}
      </animated.div>
    </>,
    document.getElementById('portal')!,
  );
};

export default BaseModal;
