import BaseModal from './BaseModal';

interface Props {
  title: string;
  description: string;
  onClose?: () => void;
  onYes?: () => void;
}

const YNModal = ({ title, description, onClose, onYes }: Props) => (
  <BaseModal onClose={onClose} modal={<>
    <div className='modal-content'>
      <div className='modal-title'>
        <h1 className='modal-title'>{title}</h1>
      </div>
      <div className='modal-description'>
        <p className='modal-description'>{description}</p>
      </div>
    </div>
    <div className='modal-control'>
      <button className='modal-button modal-yesbutton' onClick={() => { onClose?.(); onYes?.() }}>Yes</button>
      <button className='modal-button modal-nobutton' onClick={() => onClose?.()}>No</button>
    </div>
  </>} />
);

export default YNModal;
