import React, { Component } from 'react';

class Wave extends Component {
  container: React.RefObject<unknown>;
  lastUpdate: number;
  elapsed: number;
  step: number;
  frameId: any;
  constructor(props: any) {
    super(props);
    this.container = React.createRef();
    this.state = { path: '' };
    this.lastUpdate = 0;
    this.elapsed = 0;
    this.step = 0;
    this.update = this.update.bind(this);
  }

  calculateWavePoints () {
    const points = [];
    for (let i = 0; i <= Math.max((this.props as any).points, 1); i ++) {
      const scale = 100;
      const x = i / (this.props as any).points * this.width();
      const seed = (this.step + (i + i % (this.props as any).points)) * (this.props as any).speed * scale;
      const height = Math.sin(seed / scale) * (this.props as any).amplitude;
      const y = Math.sin(seed / scale) * height  + (this.props as any).height;
      points.push({x, y});
    }
    return points;
  }

  buildPath (points: any[]) {
    let svg = `M ${points[0].x} ${points[0].y}`;
    const initial = {
      x: (points[1].x - points[0].x) / 2,
      y: (points[1].y - points[0].y) + points[0].y + (points[1].y - points[0].y),
    };
    const cubic = (a: any, b: any) => ` C ${a.x} ${a.y} ${a.x} ${a.y} ${b.x} ${b.y}`;
    svg += cubic(initial, points[1]);
    let point = initial;
    for (let i = 1; i < points.length - 1; i ++) {
      point = {
        x: (points[i].x - point.x) + points[i].x,
        y: (points[i].y - point.y) + points[i].y,
      };
      svg += cubic(point, points[i + 1]);
    }
    svg += ` L ${this.width()} ${this.height() + 1000}`;
    svg += ` L 0 ${this.height()} Z`;
    return svg;
  }

  width = () => (this.container.current as any).offsetWidth;
  height = () => (this.container.current as any).offsetHeight;

  redraw () {
    this.setState({
      path: this.buildPath(this.calculateWavePoints()),
    });
  }

  draw () {
    if (!(this.props as any).paused) {
      const now = Date.now();
      this.elapsed += (now - this.lastUpdate);
      this.lastUpdate = now;
    }
    const scale = 1000;
    this.step = this.elapsed * Math.PI / scale;
    this.redraw();
  }

  update () {
    this.draw();
    if (this.frameId) {
      this.resume();
    }
  }

  resume () {
    this.frameId = window.requestAnimationFrame(this.update);
    this.lastUpdate = Date.now();
  }

  componentDidMount () {
    if (!this.frameId) {
      this.resume();
    }
  }

  componentWillUnmount () {
    window.cancelAnimationFrame(this.frameId);
    this.frameId = 0;
  }

  render () {
    return (
      <div style={{ width: '100%', display: 'inline-block', height: '6ch', background: 'linear-gradient(#0096ec, #009ff1)', marginTop: '-1px' }} ref={this.container as React.RefObject<HTMLDivElement>}>
        <svg width="100%" height="105%" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path d={(this.state as any).path} fill='var(--bg-dark-8)' />
        </svg>
      </div>
    )
  }
}

export default Wave