import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APIPresetsResponse } from '../resources/httpResponses';
import LoggedInUserComponent from './LoggedInUser';
import OkayModal from './modal/OkayModal';
import PresetComponent from './PresetComponent';

const DashComponent = () => {
  const navigate = useNavigate();
  const [presets, setPresets] = useState<APIPresetsResponse | undefined>(undefined);
  const [errorModal, setErrorModal] = useState<JSX.Element | null>(null);

  useEffect(() => {
    document.title = 'ReconBot • Presets';

    (async () => {
      if (!window.http.isLoggedIn) {
        localStorage.setItem('redirectAfterLogin', window.location.href);
        navigate('/login');
        return;
      }

      if (!presets) {
        const presetsResponse = await window.http.fetchPresets();
        setPresets(presetsResponse);
      }
    })();
  }, [navigate, presets]);

  const removePreset = async (id: string) => {
    const removeResponse = await window.http.removeOrDeletePreset(id);
    if (removeResponse.error) {
      setErrorModal(<OkayModal
        title='Something went wrong'
        description={`There was an unexpected error: ${removeResponse.error.error.message} `
          + `(${removeResponse.error.error.service}/${removeResponse.error.error.code})`}
        onClose={() => setErrorModal(null)}
      />)
    } else {
      setPresets({
        response: presets?.response?.filter((p) => p.id !== id),
      });
    }
  };

  return (
    <div className='presets'>
      <LoggedInUserComponent />
      <h1 className='presets-title'>Preset Collection</h1>
      <div className='presets-container'>
        {presets
          ? presets.response!.map((p, i) => <PresetComponent data={p} key={i} width={580} onRemove={() => removePreset(p.id)} />)
          : <div className='dash-loading' />
        }
      </div>
      {errorModal}
    </div>
  );
}

export default DashComponent;
