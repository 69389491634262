/* eslint-disable class-methods-use-this */
import axios, {
  AxiosError, AxiosInstance, HeadersDefaults, Method, ResponseType,
} from 'axios';

import {
  APILogin,
  APILoginResponse,
  APIPresetResponse,
  APIPresetsResponse,
  APIResponse,
  APIRoutesResponse,
  APIUserFortniteAccountResponse,
  APIUserResponse,
} from './resources/httpResponses';

type StringObj = { [key: string]: string };

const createQueryParams = (data: any) => {
  const form = new URLSearchParams();

  for (const key of Object.keys(data)) {
    form.append(key, data[key]);
  }

  return `?${form.toString()}`;
};

class Http {
  private axios: AxiosInstance;
  constructor() {
    this.axios = axios.create();

    (Object.keys(this.axios.defaults.headers) as (keyof HeadersDefaults)[]).forEach((h) => {
      delete this.axios.defaults.headers[h]?.['Content-Type'];
    });
  }

  public get isLoggedIn() {
    return !!localStorage.getItem('login');
  }

  public get loginData(): APILogin | undefined {
    return this.isLoggedIn ? JSON.parse(localStorage.getItem('login')!) : undefined;
  }

  private async send(method: Method, url: string, auth = false, headers: StringObj = {}, body?: any, responseType?: ResponseType): Promise<APIResponse> {
    if (auth && !this.isLoggedIn) {
      return {
        error: {
          error: {
            message: 'authorization missing',
            service: 'webv2',
            code: 'errors.authorization.missing',
            vars: [],
          },
          status: 401,
        },
      };
    }

    const finalHeaders = {
      ...headers,
      ...auth
        ? {
          Authorization: this.loginData!.token
        } : {},
    };

    try {
      const resp = await this.axios({
        method,
        url,
        headers: finalHeaders,
        data: body,
        responseType,
      });
      return { response: resp.data };
    } catch (err: any) {
      const error: AxiosError = err;
      return { error: error.response?.data as any };
    }
  }

  public async login(code: string, redirectURI: string): Promise<APILoginResponse> {
    const loginResponse = await this.send('POST', '/api/v3/discordOAuth2Login', false, {
      'Content-Type': 'application/json',
    }, {
      code,
      redirectURI,
    });

    return loginResponse;
  }

  public async fetchUser(userId?: string): Promise<APIUserResponse> {
    const userResponse = await this.send('GET', `/api/v3/user/${userId || this.loginData!.id}`, true);

    if (!userId && userResponse.response) {
      localStorage.setItem('login', JSON.stringify({
        ...this.loginData!,
        ...userResponse.response,
      }));
    }

    return userResponse;
  }

  public async fetchUserFortniteAccount(userId?: string): Promise<APIUserFortniteAccountResponse> {
    const userFortniteAccountResponse = await this.send('GET', `/api/v3/user/${userId || this.loginData!.id}/fortniteAccount`, true);
    return userFortniteAccountResponse;
  }

  public async fetchPresets(userId?: string): Promise<APIPresetsResponse> {
    const presetsResponse = await this.send('GET', `/api/v3/user/${userId || this.loginData!.id}/presets`, true);
    return presetsResponse;
  }

  public async fetchPreset(id: string): Promise<APIPresetResponse> {
    const presetResponse = await this.send('GET', `/api/v3/preset/${id}`, true);
    return presetResponse;
  }

  public async fetchPublicPresets(excludeUserId?: string, after?: string, limit?: number): Promise<APIPresetsResponse> {
    const params = createQueryParams({
      excludeUserId,
      after,
      limit,
    });

    const presetsResponse = await this.send('GET', `/api/v3/presets${params}`);
    return presetsResponse;
  }

  public async fetchRoutes(): Promise<APIRoutesResponse> {
    const routesResponse = await this.send('GET', '/api/v2/routes', true);
    return routesResponse;
  }

  public async addPreset(id: string) {
    const addResponse = await this.send('POST', `/api/v3/preset/${id}/add`, true);
    return addResponse;
  }

  public async removeOrDeletePreset(id: string) {
    const deleteResponse = await this.send('DELETE', `/api/v3/preset/${id}`, true);
    return deleteResponse;
  }
}

export default Http;
