import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export const ToS = () => {
  return (
    <>
      <h1>Terms of Service</h1>
      <p>Last Updated: November 23rd, 2024</p>
      <p>
        Please read these terms of service ("terms", "terms of service") carefully before using ReconBot
        (the "service") operated by ReconBot ("us", 'we", "our").
      </p>
      <h2>Terms</h2>
      <p>
        By using ReconBot, you are agreeing to be bound by these Terms of Service and agree that you
        are responsible for the agreement with any applicable local laws. If you disagree with any of
        these terms, you are prohibited from using ReconBot's services.
      </p>
      <h2>Limitations</h2>
      <p>
        ReconBot or its suppliers will not be held accountable for any damages that will arise with
        the use or inability to use ReconBot's services. These limitations apply even if ReconBot or
        a ReconBot authorized representative has been notified orally or in writing of the possibility
        of such damage. Some jurisdictions do not allow limitations on implied warranties or limitations
        of liability for incidental damages, these limitations may not apply to you.
      </p>
      <h2>Services "AS IS"</h2>
      <p>
        THE SERVICES ARE PROVIDED “AS IS.” WE ALSO DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, AND NON-INFRINGEMENT, AND ANY WARRANTIES
        ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. THE LAWS OF CERTAIN JURISDICTIONS OR
        STATES DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES. TO THE EXTENT SUCH WARRANTIES CANNOT
        BE DISCLAIMED UNDER THE LAWS OF YOUR JURISDICTION, WE LIMIT THE DURATION AND REMEDIES OF SUCH
        WARRANTIES TO THE FULL EXTENT PERMISSIBLE UNDER THOSE LAWS. WE DO NOT WARRANT THAT THE SERVICES
        WILL BE UNINTERRUPTED OR ERROR-FREE. WE DO NOT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
        OBTAINED FROM THE USE OF THE SERVICES OR AS TO THE ACCURACY OR RELIABILITY OF ANY INFORMATION
        OBTAINED THROUGH THE SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY
        YOU FROM US OR THROUGH THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
      </p>
      <h2>Revisions and Errata</h2>
      <p>
        The materials appearing on ReconBot's services may include technical, typographical, or
        photographic errors. ReconBot will not promise that any of the materials in this website are
        accurate, complete, or current. ReconBot may change the materials contained on its website at
        any time without notice. ReconBot does not make any commitment to update the materials.
      </p>
      <h2>Terms of Service Modifications</h2>
      <p>
        ReconBot may revise these Terms of Service for its services at any time without notice. By
        using ReconBot's services you are agreeing to be bound by the then current version of these
        Terms of Service.
      </p>
      <h2>Governing Law</h2>
      <p>
        Any claim related to ReconBot's services shall be governed by the laws of Germany without regards
        to its conflict of law provisions.
      </p>
    </>
  );
};

export const Privacy = () => {
  return (
    <>
      <h1>Privacy Policy</h1>
      <p>Last Updated: November 23rd, 2024</p>
      <p>
        This privacy notice for ReconBot ("we", "us", or "our"), describes how and why we might collect,
        store, use, and/or share ("use") your information when you use our services.
      </p>
      <h2>The information we collect</h2>
      <p>
        We collect certain information when you use ReconBot.

        This includes:
        <ul>
          <li>Your Discord user ID</li>
          <li>Your Fortnite account ID (if provided)</li>
          <li>Your saved presets</li>
          <li>Your saved custom premium bots</li>
          <li>Your premium status and expiration date</li>
        </ul>
      </p>
      <h2>How we collect the information</h2>
      <p>
        We collect the information using Discord's and EpicGames' API, as well as through your interactions.
      </p>
      <h2>How we use the information</h2>
      <p>
        We use the information we collect in various ways, including:
        <ul>
          <li>Improving our services</li>
          <li>Providing support</li>
          <li>Preventing abuse</li>
        </ul>
      </p>
      <h2>Sharing the information</h2>
      <p>
        We do not share your information with third parties.
      </p>
      <h2>Security</h2>
      <p>
        We take appropriate measures to ensure your information is secure, including:
        <ul>
          <li>Encrypting the data</li>
          <li>Storing the data on secure servers</li>
        </ul>
      </p>
      <h2>Contact us</h2>
      <p>
        Join our Discord server at <Link to="/join">https://reconbot.net/join</Link> to contact us.
      </p>
      <h2>Changes to this policy</h2>
      <p>
        We may update this policy at any time without notice.
      </p>
    </>
  );
}

const TermsComponent = ({ children }: { children: React.ReactElement }) => {
  useEffect(() => {
    document.title = 'ReconBot • Legal';
  }, []);

  return (
    <div className='terms-container'>
      {children}
    </div>
  );
}

export default TermsComponent;
