import React, { useEffect } from 'react';
import LoggedInUserComponent from './LoggedInUser';
import { Link } from 'react-router-dom';

const Wave = require('./WaveComponent').default;

const HomeComponent = () => {
  useEffect(() => {
    document.title = 'ReconBot • Home';
  }, []);

  const points = Math.round(window.innerWidth / 200);
  return (
    <div className='home'>
      <div className='home-main'>
        <img src='https://cdn.reconbot.net/universal/logo_nobg_1024.png' className='home-logo' alt='logo' />
      </div>
      <Wave points={points} paused={false} height={20} amplitude={15} speed={0.15} />
      <div className='home-description'>
        <h1 className='home-name'>ReconBot</h1>
        <p style={{ fontWeight: '400' }}>Discord Bot with Fortnite util functionality such as creating lobby bots</p>
        <div className='home-sections'>
          <button className='home-linkbutton' onClick={() => window.open('/join')}>
            Join the server
          </button>
          <button className='home-linkbutton' onClick={() => window.open('/invite')}>
            Invite the bot
          </button>
          {/* <button className='home-linkbutton' onClick={() => navigate('/browsepresets')}>
            Browse Presets
          </button> */}
        </div>
      </div>
      <div className='home-terms'>
        <Link to='/terms' className='home-termslink'>Terms of Service</Link>
        <Link to='/privacy' className='home-termslink'>Privacy Policy</Link>
      </div>
      <LoggedInUserComponent />
    </div>
  );
};
 
export default HomeComponent;
