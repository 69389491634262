import { useState } from 'react';
import YNModal from './modal/YNModal';
import { APIPreset } from '../resources/httpResponses';

const getPresetURL = (preset: APIPreset, width = 720) => {
  const additionalQueryParams = [`outfit=${encodeURIComponent(preset.outfit)}`, `pickaxe=${encodeURIComponent(preset.pickaxe)}`];
  if (preset.backpack) additionalQueryParams.push(`backpack=${encodeURIComponent(preset.backpack)}`);
  if (preset.emote) additionalQueryParams.push(`emote=${encodeURIComponent(preset.emote)}`);

  return `https://reconbot.net/api/v2/bundle?name=${encodeURIComponent(preset.name)}&format=webp`
    + `&${additionalQueryParams.join('&')}&size=${width}`;
};

const PresetComponent = (props: { data: APIPreset; width?: number; onRemove?: () => void; onAdd?: () => void }) => {
  const [isRemovalModalShown, setRemovalModalShown] = useState(false);
  
  const isLoggedIn = window.http.isLoggedIn;
  const isOwnPreset = props.data.createdBy.id === window.http.loginData?.id;
  const removeOrDelete = isOwnPreset ? 'Delete' : 'Remove';
  const isAdded = isLoggedIn && isOwnPreset ? true : props.data.usedBy.includes(window.http.loginData!.id);

  return (
    <div className='presets-preset'>
      <h1 className='presets-preset-name'>
        {props.data.name}
      </h1>
      <button className='presets-preset-button presets-preset-remove' onClick={() => setRemovalModalShown(true)} hidden={!isLoggedIn || !isAdded}>
        {removeOrDelete}
      </button>
      <button className='presets-preset-button presets-preset-add' onClick={() => props.onAdd?.()} hidden={!isLoggedIn || isAdded}>
        Add
      </button>
      <p className='presets-preset-createdby'>
        Created by {props.data.createdBy.username}#{props.data.createdBy.discriminator}
      </p>
      <p className='presets-preset-usedby'>
        Used by {props.data.usedBy.length} other{props.data.usedBy.length === 1 ? '' : 's'}
      </p>
      <img className='presets-preset-img' src={getPresetURL(props.data, props.width)} alt='preset' style={props.width ? { width: props.width } : {}} />
      {isRemovalModalShown
        ? <YNModal
            title={`${removeOrDelete} Preset`}
            description={`Are you sure that you want to ${removeOrDelete.toLowerCase()} this preset?`}
            onYes={() => props.onRemove?.()}
            onClose={() => setRemovalModalShown(false)}
          />
        : null
      }
    </div>
  );
};

export default PresetComponent;
