import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated, SpringValue } from '@react-spring/web';

const LoggedInUserComponent = () => {
  const navigate = useNavigate();
  const [isShown, setShown] = useState(false);
  const [isClickShown, setClickShown] = useState(false);

  const popupIsShown = isClickShown ? true : isShown;
  const popupStyle: { opacity: SpringValue<number>; visibility?: any } = useSpring({
    opacity: popupIsShown ? 1 : 0,
    config: {
      duration: 150,
    },
  });

  popupStyle.visibility = popupStyle.opacity.to((o) => o === 0 ? 'hidden' : 'visible');

  if (!window.http.isLoggedIn) {
    return (
      <div id='liu-container' className='liu-container'>
        <img className='liu-img' alt='avatar' src='https://cdn.reconbot.net/questionmark.png' onClick={() => {
          localStorage.setItem('redirectAfterLogin', window.location.href);
          navigate('/dash');
        }} />
      </div>
    );
  }

  return (
    <div id='liu-container' className='liu-container' onMouseLeave={() => { setShown(false); setClickShown(false); }}>
      <img className='liu-img' alt='avatar' src={window.http.loginData!.avatar} onMouseEnter={() => setShown(true)} onClick={() => setClickShown(!isClickShown)} />
      <animated.div className='liu-popup' style={popupStyle}>
        <div className='liu-popup-category' onClick={() => navigate('/')}>
          <span className='liu-popup-category-name'>Home</span>
        </div>
        <div className='liu-popup-category' onClick={() => navigate('/dash')}>
          <span className='liu-popup-category-name'>Dashboard</span>
        </div>
        <div className='liu-popup-category' onClick={() => navigate('/presets')}>
          <span className='liu-popup-category-name'>Presets</span>
        </div>
        <div className='liu-popup-category' onClick={() => navigate('/browsepresets')}>
          <span className='liu-popup-category-name'>Browse Presets</span>
        </div>
        <div className='liu-popup-category' onClick={() => navigate('/logout')}>
          <span className='liu-popup-category-name liu-popup-category-name-logout'>Logout</span>
        </div>
      </animated.div>
    </div>
  );
};

export default LoggedInUserComponent;
