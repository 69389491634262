import { useEffect } from 'react';

const RedirectComponent = (props: { to: string, func?: () => void }) => {
  useEffect(() => {
    props.func?.();
    window.location.href = props.to;
  }, [props]);

  return null;
};

export default RedirectComponent;
