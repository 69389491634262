import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import './index.css';

// components
import HomeComponent from './components/HomeComponent';
import DashComponent from './components/DashComponent';
import PresetsComponent from './components/PresetsComponent';
import RedirectComponent from './components/RedirectComponent';
import PresetLinkComponent from './components/PresetLinkComponent';
import BrowsePresetsComponent from './components/BrowsePresetsComponent';
import TermsComponent, { Privacy, ToS } from './components/TermsComponent';

const App = () => {
  useEffect(() => {
    (async () => {
      if (window.http.isLoggedIn) {
        const userResponse = await window.http.fetchUser();
        
        if (userResponse.error?.status === 403) {
          localStorage.removeItem('login');
          window.location.href = '';
        }
      }
    })();
  });

  const loginUrl = 'https://discord.com/api/oauth2/authorize?client_id=699396955666644993&redirect_uri='
    + `${encodeURIComponent(window.location.origin)}%2Fdash&response_type=code&scope=identify`;

  const inviteUrl = 'https://discord.com/oauth2/authorize?client_id=699396955666644993&permissions=2147871808&scope=bot%20applications.commands';

  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomeComponent/>} />
        <Route path='/dash' element={<DashComponent />} />
        <Route path='/preset/*' element={<PresetLinkComponent />} />
        <Route path='/presets' element={<PresetsComponent />} />
        <Route path='/browsepresets' element={<BrowsePresetsComponent />} />
        <Route path='/join' element={<RedirectComponent to='https://discord.gg/reconbot' />} />
        <Route path='/invite' element={<RedirectComponent to={inviteUrl} />} />
        <Route path='/logout' element={<RedirectComponent to={window.location.origin} func={() => localStorage.removeItem('login')} />} />
        <Route path='/login' element={<RedirectComponent to={loginUrl} />} />
        <Route path='/terms' element={<TermsComponent><ToS /></TermsComponent>} />
        <Route path='/privacy' element={<TermsComponent><Privacy /></TermsComponent>} />
        <Route path='/*' element={<Navigate to='/' />} />
      </Routes>
    </Router>
  );
};

export default App;
