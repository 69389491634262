import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { APIPreset, APIPresetResponse } from '../resources/httpResponses';
import LoggedInUserComponent from './LoggedInUser';
import OkayModal from './modal/OkayModal';
import PresetComponent from './PresetComponent';

const PresetLinkComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [preset, setPreset] = useState<APIPresetResponse | undefined>(undefined);
  const [errorModal, setErrorModal] = useState<JSX.Element | null>(null);

  useEffect(() => {
    document.title = 'ReconBot • Preset';

    (async () => {
      if (!preset) {
        const presetsResponse = await window.http.fetchPreset(location.pathname.split('/').pop()!);
        setPreset(presetsResponse);
      }
    })();
  }, [navigate, preset, location.pathname]);

  const removePreset = async () => {
    const removeResponse = await window.http.removeOrDeletePreset(preset!.response!.id);
    if (removeResponse.error) {
      setErrorModal(<OkayModal
        title='Something went wrong'
        description={`There was an unexpected error: ${removeResponse.error.error.message} `
          + `(${removeResponse.error.error.service}/${removeResponse.error.error.code})`}
        onClose={() => setErrorModal(null)}
      />)
    } else {
      setPreset({
        response: {
          ...preset!.response,
          usedBy: preset!.response!.usedBy.filter((id) => id !== window.http.loginData!.id),
        } as APIPreset,
      });
    }
  };

  const addPreset = async () => {
    const addResponse = await window.http.addPreset(preset!.response!.id);
    if (addResponse.error) {
      setErrorModal(<OkayModal
        title='Something went wrong'
        description={`There was an unexpected error: ${addResponse.error.error.message} `
          + `(${addResponse.error.error.service}/${addResponse.error.error.code})`}
        onClose={() => setErrorModal(null)}
      />)
    } else {
      setPreset({
        response: {
          ...preset!.response,
          usedBy: [...preset!.response!.usedBy, window.http.loginData!.id],
        } as APIPreset,
      });
    }
  };

  if (!preset) {
    return (
      <div className='preset-link'>
        <LoggedInUserComponent />
        <div className='preset-link-container'>
          <div className='dash-loading' />
        </div>
      </div>
    );
  }

  return (
    <div className='preset-link'>
      <LoggedInUserComponent />
      <div className='preset-link-container'>
        {preset.response
          ? <PresetComponent data={preset.response!} width={1200} onAdd={addPreset} onRemove={removePreset} />
          : <div className='presets-preset-notfound-container'>
              <h1 className='presets-preset-notfound-title'>Not Found</h1>
              <p className='presets-preset-notfound-description'>This preset wasn't found</p>
            </div>
        }
      </div>
      {errorModal}
    </div>
  );
}

export default PresetLinkComponent;
